'use client';
import React from 'react';
import { Separator } from '@design-system';
import { useClientTranslations } from '@core-systems/i18n';
import { LoginMethod } from '@core-systems/auth';
import { i18nNamespace } from '../../../../shared/i18n-namespaces';
import { LoginWithCredentials } from './login-with-credentials';
import { LoginWithGoogle } from './login-with-google';

export type LoginPageContentProps = {
  method: LoginMethod;
  safeDomain: string;
  googleClientId?: string;
};

export function LoginPageContent({ method, safeDomain, googleClientId }: LoginPageContentProps): React.JSX.Element {
  const { t } = useClientTranslations(i18nNamespace.AUTH);

  const loginMethods = [];
  if (method === LoginMethod.WITH_GOOGLE || method === LoginMethod.ANY) {
    if (googleClientId) {
      loginMethods.push(<LoginWithGoogle googleClientId={googleClientId!} />);
    }
  }
  if (method === LoginMethod.WITH_CREDENTIALS || method === LoginMethod.ANY) {
    loginMethods.push(<LoginWithCredentials safeDomain={safeDomain} />);
  }

  // If no login method is available then display the credentials form as a fallback
  // (useful for testing in local environment without Google client id)
  if (loginMethods.length === 0) {
    loginMethods.push(<LoginWithCredentials safeDomain={safeDomain} />);
  }

  return (
    <>
      <h1 className="mb-md mb-xl heading-4 sm:heading-2 text-center" data-testid="login-title">
        {t('login.form.title')}
      </h1>
      {loginMethods.map((loginMethod, index) => {
        const items = [<React.Fragment key={`login-method-${index}`}>{loginMethod}</React.Fragment>];
        const isLastMethod = index === loginMethods.length - 1;
        if (!isLastMethod) {
          items.push(
            <Separator key={`separator-${index}`} className="my-md">
              <div className="p-xs body-3">{t('login.form.separator')}</div>
            </Separator>,
          );
        }
        return items;
      })}
    </>
  );
}
